// import React, { useState } from "react";
// import "../styles/HomeStyles.css";
// import { GoArrowRight } from "react-icons/go";
// import Modal from "react-modal";
// import ScrollToTop from "../components/ScrollToTop";
// import { Link } from "react-router-dom";
// import ReactPlayer from "react-player"; // Import ReactPlayer

// const Home = () => {
//   const [activeVideo, setActiveVideo] = useState(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const videoLinks = {
//     Athlantix:
//       "https://usc1.contabostorage.com/76eefdbd0fc2453587f2e04edaa34a37:axillio-content/Axillio%20Website/Athlantix%20Explainer%20Video.mp4?autoplay=1&mute=1",
//     Roam: "https://usc1.contabostorage.com/76eefdbd0fc2453587f2e04edaa34a37:axillio-content/Axillio Website/ROAM Explainer Video.mp4?autoplay=1&mute=1",
//     Velara: "https://www.youtube.com/embed/YOUR_VIDEO_ID?autoplay=1&mute=1",
//     "Sky AI": "https://www.youtube.com/embed/YOUR_VIDEO_ID?autoplay=1&mute=1",
//     CareGen: "https://www.youtube.com/embed/YOUR_VIDEO_ID?autoplay=1&mute=1",
//     Creta: "https://www.youtube.com/embed/YOUR_VIDEO_ID?autoplay=1&mute=1",
//   };

//   const openModal = (videoName) => {
//     setActiveVideo(videoName);
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setActiveVideo(null);
//     setIsModalOpen(false);
//   };

//   return (
//     <>
//       <ScrollToTop />
//       <div className="bg-[#e6e2dd] pt-12 min-h-screen font-gilroyRegular">
//         {/* navbar */}
//         <div className="flex flex-row items-center justify-between px-8 md:px-16 lg:px-24 xl:px-48">
//           <Link to={"/"}>
//             <img
//               alt="axillio-logo"
//               src="/images/axillio-logo.png"
//               className="w-full h-auto"
//             />
//           </Link>
//           <div className="flex flex-row items-center space-x-1 hover:cursor-pointer">
//             <h1 className="text-[#303030] text-base md:text-xl font-semibold">
//               Start a Project
//             </h1>
//             <GoArrowRight className="text-[#303030] text-base md:text-xl" />
//           </div>
//         </div>

//         {/* section one */}
//         <div className="flex flex-col items-center justify-center px-8 pt-20 space-y-20 md:px-16 lg:px-24 xl:px-48 md:pt-40 lg:pt-80 md:space-y-40 lg:space-y-80">
//           <div className="flex flex-col items-center justify-center space-y-4 md:space-y-6">
//             <h1 className="text-[#303030] text-lg md:text-xl font-semibold">
//               venture studio
//             </h1>
//             <h1 className="text-[#303030] text-2xl md:text-4xl text-center font-gilroyBold">
//               <span className="underline decoration-2 decoration-[#303030] underline-offset-2 font-normal font-gilroyRegular">
//                 Simplify your
//               </span>{" "}
//               Product launch <br />
//               <span className="underline decoration-2 decoration-[#303030] underline-offset-2 font-normal font-gilroyRegular">
//                 and
//               </span>{" "}
//               Distribution
//             </h1>
//           </div>

//           <div className="flex flex-col items-center justify-center space-y-8">
//             {Object.keys(videoLinks).map((name) => (
//               <h1
//                 key={name}
//                 className="text-[#303030] text-3xl md:text-5xl lg:text-6xl font-normal relative after:content-[''] after:block after:h-[2px] after:bg-[#303030] after:mt-4 after:mx-auto hover:cursor-pointer"
//                 onMouseEnter={() => openModal(name)}
//               >
//                 {name} .
//               </h1>
//             ))}

//             {isModalOpen && (
//               <div className="fixed inset-0 z-50 flex items-center justify-center">
//                 <div
//                   className={`relative rounded-lg w-[90vw] h-[50vh] md:w-[75vw] md:h-[60vh] lg:w-[140vh] lg:h-[80vh] transition-all duration-500 ease-in-out transform ${
//                     isModalOpen
//                       ? "opacity-100 translate-y-0"
//                       : "opacity-0 translate-y-10"
//                   }`}
//                   onMouseLeave={closeModal} // This closes the modal when the mouse leaves the modal area
//                 >
//                   {activeVideo && (
//                     <ReactPlayer
//                       url={videoLinks[activeVideo]}
//                       playing={true}
//                       controls={true}
//                       muted={true}
//                       width="100%"
//                       height="100%"
//                     />
//                   )}
//                   <button
//                     onClick={closeModal}
//                     className="absolute p-2 text-2xl text-white rounded-full top-2 right-2"
//                   >
//                     ✕
//                   </button>
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>

//         <div className="bg-[#1a1a1a] flex flex-col space-y-16 md:space-y-28 pb-12 pt-12 md:pt-24 mt-32 md:mt-64 px-8 md:px-16 lg:px-24 xl:px-48">
//           <div className="flex flex-col justify-between md:flex-row">
//             <div className="flex flex-col space-y-6">
//               <h1 className="text-[#e6e2dd] text-lg md:text-2xl font-semibold">
//                 AXILLIO
//               </h1>
//               <div className="flex flex-col">
//                 <h1 className="text-sm md:text-md text-[#d6d2cd]">
//                   San Francisco
//                 </h1>
//                 <h1 className="text-sm md:text-md text-[#d6d2cd]">
//                   Delaware UAE
//                 </h1>
//               </div>
//               <h1 className="text-sm md:text-md text-[#d6d2cd]">
//                 example@axillio.com
//               </h1>
//             </div>
//             <div className="flex flex-col space-y-4">
//               <h1 className="text-sm md:text-md text-[#d6d2cd]">Portfolio</h1>
//               <h1 className="text-sm md:text-md text-[#d6d2cd]">Services</h1>
//               <h1 className="text-sm md:text-md text-[#d6d2cd]">About</h1>
//               <Link
//                 to={"/categories"}
//                 className="text-sm md:text-md text-[#d6d2cd]"
//               >
//                 Blogs
//               </Link>
//             </div>
//             <div className="flex flex-col space-y-6">
//               <div className="flex flex-row items-center pt-8 space-x-1 md:pt-0 hover:cursor-pointer ">
//                 <h1 className="text-[#e6e2dd] text-lg md:text-xl font-semibold ">
//                   Start a Project
//                 </h1>
//                 <GoArrowRight className="text-[#e6e2dd] text-lg md:text-xl" />
//               </div>
//             </div>
//           </div>
//           <div className="flex flex-col items-center justify-between space-y-8 md:space-y-12">
//             <h1 className="text-sm md:text-md text-[#d6d2cd]">Follow us on:</h1>
//             <div className="flex flex-row items-center justify-center space-x-4 md:space-x-8">
//               <img
//                 alt="behance"
//                 src="./images/behance_logo.png"
//                 className="w-8 h-auto md:w-12 lg:w-full"
//               />
//               <img
//                 alt="x"
//                 src="./images/x_logo.png"
//                 className="w-8 h-auto md:w-12 lg:w-full"
//               />
//               <img
//                 alt="football"
//                 src="./images/footbal-logo.png"
//                 className="w-8 h-auto md:w-12 lg:w-full"
//               />
//               <img
//                 alt="linkedin"
//                 src="./images/linkedin_symbol.png"
//                 className="w-8 h-auto md:w-12 lg:w-full"
//               />
//               <img
//                 alt="insta"
//                 src="./images/insta-logo.png"
//                 className="w-8 h-auto md:w-12 lg:w-full"
//               />
//               <img
//                 alt="youtube"
//                 src="./images/youtube-logo.png"
//                 className="w-8 h-auto md:w-12 lg:w-full"
//               />
//             </div>
//             <h1 className="text-sm md:text-md text-[#d6d2cd]">
//               © 2024 Axillio. All Rights Reserved
//             </h1>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Home;

import React, { useState, useEffect } from "react";
import "../styles/HomeStyles.css";
import { GoArrowRight } from "react-icons/go";
import ScrollToTop from "../components/ScrollToTop";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { Helmet } from "react-helmet";

const Home = () => {
  const [activeVideo, setActiveVideo] = useState(null);
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [preloadedVideos, setPreloadedVideos] = useState({});

  const [isHovered, setIsHovered] = useState({
    Athlantix: false,
    Roam: false,
    Velara: false,
    "Sky AI": false,
    CareGen: false,
    Creta: false,
  });

  const videoLinks = {
    Athlantix: {
      name: "Athlantix",
      videoUrl:
        "https://axilliostorage.blob.core.windows.net/videos/Athlantix%20Explainer%20Video.mp4?sp=r&st=2024-12-04T19:38:58Z&se=2025-12-01T03:38:58Z&spr=https&sv=2022-11-02&sr=b&sig=dzbIngtzj7JSRSeFOzY8LWISYSdjr2FwVzMjaiEuJ8A%3D",
      path: "/athlantix",
    },
    Roam: {
      name: "Roam",
      videoUrl:
        "https://axilliostorage.blob.core.windows.net/videos/ROAM%20Explainer%20Video.mp4?sp=r&st=2024-12-04T19:37:55Z&se=2025-12-01T03:37:55Z&spr=https&sv=2022-11-02&sr=b&sig=x8C3DoGUeTFrJptBhNDqzWsMqd%2BrTmYoyGEhP3RXyY0%3D",
      path: "/roam",
    },
    Velara: {
      name: "Velara",
      path: "/velara",
      videoUrl: "./images/velara-image.jpg",
    },
    "Sky AI": {
      name: "Sky AI",
      videoUrl: "./images/skyAi-image.jpg",
    },
    CareGen: {
      name: "CareGen",
      videoUrl: "./images/careGen-image.jpg",
    },
    Creta: {
      name: "Creta",
      videoUrl: "./images/creta-image.jpg",
    },
  };

  const handleMouseMove = (e) => {
    setMousePosition({ x: e.clientX, y: e.clientY });
  };

  const showVideo = (videoName) => {
    setActiveVideo(videoName);
    setIsVideoVisible(true);
  };

  const hideVideo = () => {
    setActiveVideo(null);
    setIsVideoVisible(false);
  };

  const preloadVideo = (url) => {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      video.src = url;
      video.preload = "auto"; // Preload video data

      // Resolve the promise once enough data is loaded
      video.oncanplaythrough = () => resolve(video);
    });
  };

  useEffect(() => {
    const preloadVideos = async () => {
      const preloaded = {};
      for (const name of Object.keys(videoLinks)) {
        preloaded[name] = await preloadVideo(videoLinks[name].videoUrl);
      }
      setPreloadedVideos(preloaded);
    };

    preloadVideos();
  }, []);

  const handleMouseEnter = (videoName) => {
    setIsHovered((prev) => ({ ...prev, [videoName]: true }));
  };

  const handleMouseLeave = (videoName) => {
    setIsHovered((prev) => ({ ...prev, [videoName]: false }));
  };

  return (
    <div onMouseMove={handleMouseMove}>
      <Helmet>
        <title>Axillio</title>
        <meta
          name="description"
          content="Building distribution first startups."
        />
        <meta
          name="keywords"
          content="AI solutions, product launch, distribution, Athlantix, Roam, Velara"
        />
      </Helmet>
      <ScrollToTop />
      <div className="bg-[#e6e2dd] pt-12 min-h-screen font-gilroyRegular">
        {/* navbar */}
        <div className="flex flex-row items-center justify-between px-8 md:px-16 lg:px-24 xl:px-48">
          <Link to={"/"}>
            <img
              alt="axillio-logo"
              src="/images/axillio-logo.png"
              className="w-full h-auto"
            />
          </Link>
          <div className="flex flex-row items-center space-x-1 hover:cursor-pointer">
            <h1 className="text-[#303030] text-base md:text-xl font-semibold">
              Start a Project
            </h1>
            <GoArrowRight className="text-[#303030] text-base md:text-xl" />
          </div>
        </div>

        {/* section one */}
        <div className="flex flex-col items-center justify-center px-8 pt-20 space-y-20 md:px-16 lg:px-24 xl:px-48 md:pt-56 lg:pt-64 md:space-y-40 lg:space-y-80">
          <div className="flex flex-col items-center justify-center space-y-4 md:space-y-6">
            <h1 className="text-[#303030] text-lg md:text-xl font-semibold">
              venture studio
            </h1>
            <h1 className="text-[#303030] text-2xl md:text-4xl text-center font-gilroyBold">
              <span className="underline decoration-2 decoration-[#303030] underline-offset-2 font-normal font-gilroyRegular">
                Simplify your
              </span>{" "}
              Product launch <br />
              <span className="underline decoration-2 decoration-[#303030] underline-offset-2 font-normal font-gilroyRegular">
                and
              </span>{" "}
              Distribution
            </h1>
          </div>

          <div className="flex flex-col items-center justify-center space-y-8">
            {Object.keys(videoLinks).map((item) => (
              <Link
                to={videoLinks[item]?.path ? `${videoLinks[item]?.path}` : "/"} // Provide "#" as fallback if no path exists
                key={videoLinks[item]?.name} // Use videoLinks[item].name here
                className={`text-[#303030] text-3xl md:text-5xl lg:text-6xl font-normal relative after:content-[''] after:block after:h-[2px] after:bg-[#303030] after:mt-4 after:mx-auto hover:cursor-pointer ${
                  isHovered[videoLinks[item]?.name]
                    ? "mix-blend-mode-difference filter-invert"
                    : ""
                }`}
                style={{
                  position: "relative",
                  zIndex: isHovered[videoLinks[item]?.name] ? 1100 : "auto",
                }}
                onMouseEnter={() => {
                  showVideo(videoLinks[item]?.name);
                  handleMouseEnter(item); // Pass item instead of videoUrl
                }}
                onMouseLeave={() => {
                  hideVideo();
                  handleMouseLeave(item); // Pass item instead of videoUrl
                }}
              >
                {videoLinks[item]?.name} .
              </Link>
            ))}

            <AnimatePresence>
              {isVideoVisible && activeVideo && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.6 }}
                  style={{
                    position: "fixed",
                    width: "100vw", // Full width of the viewport
                    height: "100vh", // Full height of the viewport
                    top: 0, // Ensure the element starts at the top of the viewport
                    left: 0, // Ensure the element starts at the left of the viewport
                    pointerEvents: "none", // Prevent interaction with other elements
                    zIndex: 1000, // Ensure it's displayed above other content
                  }}
                >
                  {videoLinks[activeVideo]?.videoUrl?.includes(".mp4") ? (
                    <video
                      src={videoLinks[activeVideo]?.videoUrl}
                      autoPlay
                      loop
                      muted
                      style={{
                        position: "fixed",
                        width: "100vw",
                        height: "100vh",
                        top: 0,
                        left: 0,
                        objectFit:
                          window.innerWidth < 768 ? "contain" : "cover", // Adjust based on screen width
                      }}
                    />
                  ) : (
                    <img
                      src={videoLinks[activeVideo]?.videoUrl}
                      alt={activeVideo}
                      style={{
                        position: "fixed",
                        width: "100vw",
                        height: "100vh",
                        top: 0,
                        left: 0,
                        objectFit:
                          window.innerWidth < 768 ? "contain" : "cover", // Adjust based on screen width
                      }}
                    />
                  )}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>

        <div className="bg-[#1a1a1a] flex flex-col space-y-16 md:space-y-28 pb-12 pt-12 md:pt-24 mt-32 md:mt-64 px-8 md:px-16 lg:px-24 xl:px-48">
          <div className="flex flex-col justify-between md:flex-row">
            <div className="flex flex-col space-y-6">
              <h1 className="text-[#e6e2dd] text-lg md:text-2xl font-semibold">
                AXILLIO
              </h1>
              <div className="flex flex-col">
                <h1 className="text-sm md:text-md text-[#d6d2cd]">
                  San Francisco
                </h1>
                <h1 className="text-sm md:text-md text-[#d6d2cd]">
                  Delaware UAE
                </h1>
              </div>
              <h1 className="text-sm md:text-md text-[#d6d2cd]">
                contact@axillio.com
              </h1>
            </div>
            <div className="flex flex-col space-y-4">
              <h1 className="text-sm md:text-md text-[#d6d2cd]">Portfolio</h1>
              <h1 className="text-sm md:text-md text-[#d6d2cd]">Services</h1>
              <h1 className="text-sm md:text-md text-[#d6d2cd]">About</h1>
              <Link
                to={"/categories"}
                className="text-sm md:text-md text-[#d6d2cd]"
              >
                Blogs
              </Link>
            </div>
            <div className="flex flex-col space-y-6">
              <div className="flex flex-row items-center pt-8 space-x-1 md:pt-0 hover:cursor-pointer ">
                <h1 className="text-[#e6e2dd] text-lg md:text-xl font-semibold ">
                  Start a Project
                </h1>
                <GoArrowRight className="text-[#e6e2dd] text-lg md:text-xl" />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-between space-y-8 md:space-y-12">
            <h1 className="text-sm md:text-md text-[#d6d2cd]">Follow us on:</h1>
            <div className="flex flex-row items-center justify-center space-x-4 md:space-x-8">
              <a
                href="https://www.behance.net/axillio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="behance"
                  src="./images/behance_logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://x.com/AliMukhtar081"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="x"
                  src="./images/x_logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://dribbble.com/axillio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="football"
                  src="./images/footbal-logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/axillio/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="linkedin"
                  src="./images/linkedin_symbol.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://www.instagram.com/axillioofficial/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="insta"
                  src="./images/insta-logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://www.youtube.com/@Axillio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="youtube"
                  src="./images/youtube-logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
            </div>

            <h1 className="text-sm md:text-md text-[#d6d2cd]">
              © 2024 Axillio. All Rights Reserved
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
